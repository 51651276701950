import React, { useContext } from 'react'
import { Link } from 'gatsby'

import ContextCurrentLocale from './ContextCurrentLocale'
import Header from './Header'
import accountForm from '../content/account-form'

import styles from './404.module.scss'

export default function FourOfour() {
  const lang = useContext(ContextCurrentLocale)
  const [header, button] = accountForm[404][lang].split('%button%')
  return (
    <>
      <Header accountForm={accountForm} lang={lang} step={0} />
      <main className={styles.main}>
        <h1 className={styles.header}>{header}</h1>
        <Link to="/" className={styles.button}>
          <span>{button}</span>
        </Link>
      </main>
    </>
  )
}
